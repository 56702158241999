import { authHeaders, getErrorMessage, throwError } from "./utils";

export const fetchProfile = async () => {
    const response = await fetch('/api/me/user/', { headers: authHeaders() });
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching profile", response.status)
    }
}

export const updateProfile = async (properties) => {
    const response = await fetch(`/api/me/user/`,
        {
            method: "PUT",
            headers: authHeaders(),
            body: JSON.stringify({ ...properties })
        }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        const message = getErrorMessage(await response.json())
        throwError(message ? message : "Error updating profile", response.status)
    }
}