import {
    Navigate,
    Outlet,
} from 'react-router-dom';
import { hasNoSession } from '../models/Session';
import { useEffect, useState } from 'react';
import Loading from '../viewer/components/Loading';
import { refreshIfExpiredSession } from '../models/RefreshSession';

const ProtectedRoute = ({ redirectPath = '/login' }) => {
    const [refreshed, setRefreshed] = useState(false)
    const [loginRequired, setLoginRequired] = useState(false)

    useEffect(() => {
        const callRefresh = async () => {
            const success = await refreshIfExpiredSession()
            if (success) {
                setRefreshed(true)
            } else {
                setLoginRequired(true)
            }
        }
        if (!refreshed) {
            callRefresh();
        }
    }, [refreshed]);

    if (hasNoSession()) {
        return <Navigate to={redirectPath} replace />;
    }
    if (loginRequired) {
        return <Navigate to={"/login"} replace />;
    }
    if (!refreshed) {
        return <Loading />;
    }

    return <Outlet />;
};

export default ProtectedRoute;