import React, { useState, useEffect } from "react";
import { fetchSubscriptions } from "../../api/subscriptions";
import Card from "../styled/Card";
import Heading from "../../viewer/components/styled/Heading";
import { useRouteLoaderData } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import Spinner from "../../viewer/components/styled/Spinner";

const Subscription = () => {
  const [subscriptions, setSubscriptions] = useState(null)
  const account = useRouteLoaderData('account');

  useEffect(() => {
    const fetchData = async () => {
      setSubscriptions(await fetchSubscriptions(account.id));
    }
    if (account) {
      fetchData();
    }
  }, [account]);

  const heading = "Billing Details"
  if (!subscriptions) {
    return (
      <>
        <Heading>{heading}</Heading>
        <Spinner />
      </>
    )
  }

  const subscription = subscriptions[0];

  const next_payment_at = () => {
    return Intl.DateTimeFormat(navigator.language, { month: 'long', day: 'numeric' }).format(Date.parse(subscription.next_payment_at))
  }

  return (
    <>
      <Heading>{heading}</Heading>
      <div className="flex flex-row">
        <Card>
          <div className="flex flex-row justify-between">
            <p className="font-light">Plan </p>
            <a className="text-primary" href={subscription.manage_plan_url}>Manage Plan&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
          </div>
          <div className="flex flex-row justify-between">
            <div className="font-bold text-lg">{subscription.plan_name}</div>
            <div className="font-bold text-lg">{subscription.seats} Seats</div>
          </div>
          <div>{subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1)}</div>
        </Card>
        <Card>
          <div className="flex flex-row justify-between">
            <p className="font-light">Next Billing Date</p>
            <a className="text-primary" href={subscriptions[0].manage_payment_url}>Manage Payment&nbsp;<FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a>
          </div>
          <div className="font-bold text-lg">{next_payment_at()}</div>
          <div>$ {(subscription.next_payment_cents / 100).toFixed(2)}</div>
        </Card>

      </div>
    </>
  )
}

export default Subscription;
