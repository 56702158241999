import { authHeaders, throwError } from "./utils";

export const fetchSubscriptions = async (accountId) => {
    const response = await fetch('/api/subscriptions/?' + new URLSearchParams({
        account_id: accountId
    }),
        { headers: authHeaders() }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching subscription", response.status)
    }
}