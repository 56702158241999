import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { loginGoogleUser } from "../../api/register";
import Loading from "../../viewer/components/Loading";
import { storeSession } from "../../models/Session";
import ErrorPage from "../ErrorPage";

const Callback = () => {
    const [searchParams] = useSearchParams()
    const [error, setError] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const login = async () => {
            try {
                const jwt = await loginGoogleUser(searchParams.get("code"), searchParams.get("state"))
                storeSession(jwt)
                navigate("/profile")
            } catch (e) {
                setError(await e.json().message)
            }
        }
        if (searchParams && navigate) {
            login()
        }
    }, [searchParams, navigate]);

    if (error) {
        return <ErrorPage message={error} />
    } else {
        return <Loading />
    }
}

export default Callback;