import { authHeaders, throwError } from "./utils";

export const fetchProjectHistory = async (projectId) => {
    const response = await fetch(`/api/project_history/${projectId}/`,
        { headers: authHeaders() },
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching projects", response.status)
    }
}
