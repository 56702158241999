import { jwtDecode } from "jwt-decode";
import { refreshJwt } from '../api/register';
import { clearSession, getSession, storeSession } from "./Session";

export const refreshIfExpiredSession = async () => {
    const session = await getSession()
    if (!session) {
        return false;
    }
    const refresh = jwtDecode(session["refresh"])
    const access = jwtDecode(session["access"])
    const now = (Date.now() + 100) / 1000; //Go 100 milliseconds forward to avoid cutting it too close
    if (now > access["exp"] && now > refresh["exp"]) {
        clearSession()
        return false;
    }
    if (now > access["exp"]) {
        const access = await refreshJwt(session["refresh"])
        storeSession({ ...session, access: access["access"] })
    }
    return true
}

export const callRefreshAndNavigate = async (navigate) => {
    const success = await refreshIfExpiredSession()
    if (success) {
        navigate(0)
    } else {
        navigate("/login")
    }
}
