import React, { useState, useEffect } from "react";
import { fetchStages, updateStage } from "../../api/stages";
import Heading from "../../viewer/components/styled/Heading";
import { ButtonHighlight } from "../../viewer/components/styled/Button";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { stagePath } from "../../models/Paths";
import Spinner from "../../viewer/components/styled/Spinner";
import Toggle from "../../viewer/components/styled/Toggle";

const Stages = () => {
  const [stages, setStages] = useState(null)
  const [showArchived, setShowArchived] = useState(false)
  const account = useRouteLoaderData('account');


  useEffect(() => {
    const fetchData = async () => {
      setStages(await fetchStages(account.id, { archived: false }));
    }
    if (account) {
      fetchData();
    }
  }, [account]);

  const archiveStage = async (stageId, archived) => {
    await updateStage(stageId, { archived })
    refreshStages(showArchived);
  }

  const refreshStages = async (showArchived) => {
    const params = {}
    if (!showArchived) {
      params["archived"] = false;
    }
    setStages(await fetchStages(account.id, params));
  }

  const handleShowArchivedClick = (event) => {
    setShowArchived(!showArchived)
    refreshStages(!showArchived);
  }

  const heading = "Stages"
  if (!stages) {
    return (
      <>
        <Heading>{heading}</Heading>
        <Spinner />
      </>
    )
  }

  return (
    <>
      <Heading>
        <div>{heading}</div>
        <Toggle label={"Show Archived"} value={showArchived} onClick={handleShowArchivedClick}></Toggle>
      </Heading>
      <ol>
        {stages.map((stage) =>
          <li key={`${stage.name}`} className="flex flex-row justify-between hover:bg-gray-100 items-center p-1">
            <Link to={stagePath(account.slug, stage.name)}>{stage.name}</Link>
            <ButtonHighlight onClick={() => archiveStage(stage.id, !stage.archived)}>{stage.archived ? 'Unarchive' : 'Archive'}</ButtonHighlight>
          </li>
        )}
      </ol>
    </>
  )
}

export default Stages;
