import { useRouteLoaderData } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '../viewer/components/Breadcrumbs';
import TrialBanner from "./subscription/TrialBanner";
import ProjectHistory from './ProjectHistory';
import { SearchParamsProvider } from '../viewer/contexts/SearchParamsContext';

const StageContainer = () => {
  const { id } = useRouteLoaderData('account');
  return (
    <>
      <TrialBanner accountId={id} />
      <div className="flex flex-row justify-between items-center whitespace-nowrap border-b border-gray-200 pb-2">
        <Breadcrumbs />
        <ProjectHistory />
      </div>
      <SearchParamsProvider>
        <Outlet />
      </SearchParamsProvider>
    </>
  );
}

export default StageContainer;
