import { useQuery } from '@tanstack/react-query'
import { fetchSubscriptions } from '../../api/subscriptions';
import { Button } from "../../viewer/components/styled/Button";

const Banner = (props) => {
    const query = useQuery({ queryKey: ['subscriptions'], queryFn: () => fetchSubscriptions(props.accountId) })

    if (!query.data || !query.data[0]) {
        return
    }

    const subscription = query.data[0]

    const handleOnClickPlan = () => {
        window.location.href = subscription.manage_plan_url;
    }
    const handleOnClickPayment = () => {
        window.location.href = subscription.manage_payment_url;
    }

    if (!subscription || subscription.status !== "trialing") {
        return
    }

    const expiration = () => {
        return Intl.DateTimeFormat(navigator.language, { weekday: 'long', month: 'short', day: 'numeric' }).format(Date.parse(subscription.trial_end)) // Friday, Dec 27
    }

    return (
        <div className="flex flex-row bg-highlight-100 justify-center-items p-2 rounded-lg m-2 text-lg">
            <div className='grow pl-2 m-auto'>Your trial ends on {expiration()}</div>
            <div>
                <Button className="mr-2" onClick={handleOnClickPlan}>Manage Plan</Button>
                <Button onClick={handleOnClickPayment}>Manage Payment</Button>
            </div>
        </div>
    );
}

export default Banner;
