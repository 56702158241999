import Heading from "../../viewer/components/styled/Heading";
import Input from "../../viewer/components/styled/Input"
import React, { useState, useEffect } from "react";
import { createUser } from "../../api/users";
import { Link, useSearchParams } from "react-router-dom";
import { Button, ButtonOutline, GoogleSvg } from "../../viewer/components/styled/Button";
import CenterForm from "../styled/CenterForm";
import { Error, Success } from "../styled/Alerts";
import { getGoogleAuthUrl } from "../../api/register";

const Register = () => {
    const initialEmail = useSearchParams().email;
    const [email, setEmail] = useState(initialEmail ? initialEmail : "")
    const [disabled, setDisabled] = useState(true)
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const handlePasswordConfirmChange = (event) => {
        setPasswordConfirm(event.target.value)
    }

    useEffect(() => {
        if (password === passwordConfirm && (password.length > 7 || password.length === 0)) {
            setError(null)
            setDisabled(false)
        } else {
            setError("Password is too short (8 characters) or does not match confirmation")
            setDisabled(true)
        }
    }, [password, passwordConfirm]);

    const register = async () => {
        try {
            await createUser(email, password)
            setSuccess(true)
        } catch {
            setError("There was an error creating user.")
        }
    }
    const googleLogin = async () => {
        const authorizationUrl = await getGoogleAuthUrl()
        window.location.href = authorizationUrl["authorization_url"]
    };

    const renderMessage = () => {
        if (success) {
            return <Success>User created successfully, activation email sent.</Success>
        }
        if (!error) {
            return null
        }
        return (
            <Error>{error}</Error>
        )
    }
    return (
        <CenterForm>
            <Heading>Register for a new account</Heading>
            {renderMessage()}
            <div className="space-y-4 md:space-y-6">
                <ButtonOutline className="w-full flex justify-center" onClick={googleLogin}><GoogleSvg /><span className="ml-1">Sign up with Google</span></ButtonOutline>
                <hr></hr>
                <Input label="Email" name="email" value={email} onChange={handleEmailChange} alt="email"></Input>
                <Input label="Password" name="password" value={password} onChange={handlePasswordChange} type="password" alt="password"></Input>
                <Input label="Confirm Password" name="passwordConfirm" value={passwordConfirm} onChange={handlePasswordConfirmChange} type="password" alt="passwordConfirm"></Input>
                <Button name="register" className="w-full" disabled={disabled} onClick={register}>Sign Up</Button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already have an account? <Link to="/login" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login</Link>
                </p>
            </div>
        </CenterForm>
    );
}

export default Register;
