import { fetchProjects } from "../api/projects";

export const loadStage = async (params) => {
    const stageName = params.params.stageName;
    const projects = await fetchProjects(null, null, stageName)
    return {
        stageName: params.params.stageName,
        accountSlug: params.params.accountSlug,
        projects
    };
}