import { throwError } from './utils';

export const createUser = async (email, password) => {
    const response = await fetch('/api/register/users/',
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({ email, password }).toString()
        }
    );
    if (response.status === 201) {
        return await response.json();
    } else {
        throwError("Error creating user", response.status)
    }
}