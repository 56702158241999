import Cookies from 'js-cookie';
import { authHeaders, throwError } from './utils';

export const fetchInvites = async (accountId) => {
    const response = await fetch('/api/invites/?' + new URLSearchParams({
        account_id: accountId
    }), { headers: authHeaders() });
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching invites", response.status)
    }
}

export const createInvite = async (email, account) => {
    const response = await fetch('/api/invites/',
        {
            method: "POST",
            headers: authHeaders(),
            body: JSON.stringify({ email, account: account.id })
        }
    );
    if (response.status === 201) {
        return await response.json();
    } else {
        throwError("Error creating invite", response.status)
    }
}

export const deleteInvite = async (id) => {
    const csrftoken = Cookies.get('csrftoken');
    const response = await fetch(`/api/invites/${encodeURIComponent(id)}/`,
        {
            method: 'DELETE',
            headers: { "Content-Type": "application/json", 'X-CSRFToken': csrftoken },
        });
    if (response.status === 204) {
        return true;
    } else {
        return false;
    }
}
