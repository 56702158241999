import { Link, useNavigate } from 'react-router-dom';
import logo from '../viewer/images/logo.png';
import { Button } from "../viewer/components/styled/Button";
import { clearSession, hasNoSession } from '../models/Session';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearSession()
    navigate("/")
  };

  const handleLogin = () => {
    navigate("/login")
  };

  const renderContent = () => {
    if (hasNoSession()) {
      return (
        <div className="flex items-center">
          <Button onClick={handleLogin}>Login</Button>
        </div>
      )
    } else {
      return (
        <div className="flex items-center">
          <Link to="/profile">Profile</Link>
          <Button className="ml-2" onClick={handleLogout}>Logout</Button>
        </div>
      )
    }

  }

  return (
    <nav className="relative w-full flex flex-wrap items-center justify-between py-4 px-4 mb-2 bg-gray-100 text-gray-600 hover:text-gray-900 focus:text-gray-900 shadow-sm navbar navbar-expand-lg navbar-light">
      <img className='h-8' src={logo} alt="Logo" />
      {renderContent()}
    </nav >
  );
}

export default Navbar;
