import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Container } from "../viewer/components/styled/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { fetchProfile } from "../api/profile";
import { callRefreshAndNavigate } from "../models/RefreshSession";

const Profile = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setProfile(await fetchProfile());
            } catch (error) {
                if (error.status === 401) {
                    callRefreshAndNavigate(navigate);
                }
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const activeClasses = "inline-block p-4 text-primary-600 border-b-2 border-primary-600 rounded-t-lg active dark:text-primary-500 dark:border-primary-500"
    const inactiveClasses = "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"

    const profileActive = location.pathname.endsWith("profile")
    const settingsActive = location.pathname.endsWith("settings")

    return (
        <Container>
            <div className="grow flex h-screen flex-col">
                <div className="flex flex-row text-2xl items-center"><FontAwesomeIcon icon={faUser} />&nbsp;{profile ? profile.name : ""}</div>
                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
                    <ul className="flex flex-wrap -mb-px">
                        <li className="me-2">
                            <Link className={profileActive ? activeClasses : inactiveClasses} to={"/profile"}>Accounts</Link>
                        </li>
                        <li className="me-2">
                            <Link className={settingsActive ? activeClasses : inactiveClasses} to={"/profile/settings"}>Settings</Link>
                        </li>
                    </ul>
                </div>
                <Outlet />
            </div>
        </Container>
    );
}

export default Profile;