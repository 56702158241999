import React from "react";
import App from './App'
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QueryProvider } from './viewer/contexts/QueryContext'
import { fetchTracesQuery } from "./queries/traces"
import { fetchProjectHistoryQuery } from "./queries/project-history"

const queryClient = new QueryClient()

function Providers() {
    return (
        <QueryClientProvider client={queryClient}>
            <QueryProvider value={{ fetchTracesQuery, fetchProjectHistoryQuery }}>
                <RouterProvider router={App} />
            </QueryProvider>
        </QueryClientProvider>
    );
}

export default Providers;