
const LeftForm = (props) => {
    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mr-auto w-96 lg:py-0">
            <div className="w-full bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default LeftForm;