import React, { useState, useEffect } from "react";
import { fetchAccounts } from "../../api/accounts";
import Loading from "../../viewer/components/Loading";
import Heading from "../../viewer/components/styled/Heading";
import { Link, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { callRefreshAndNavigate } from "../../models/RefreshSession";

const Accounts = () => {
    const profile = useRouteLoaderData('profile');
    const [accounts, setAccounts] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                setAccounts(await fetchAccounts())
            } catch (error) {
                if (error.status === 401) {
                    callRefreshAndNavigate(navigate);
                }
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const renderAccount = () => {
        return (
            <>
                <Heading>Accounts</Heading>
                <ol>
                    {accounts.map((account) =>
                        <li key={account.id} className="flex flex-row justify-between items-center">
                            <Link className="text-primary" to={`/${account.slug}`}>{account.name}</Link>
                        </li>
                    )}
                </ol>
            </>
        )
    }

    const renderLoading = () => {
        return (
            <Loading />
        )
    }

    if (profile === null || accounts === null) {
        return renderLoading()
    } else {
        return renderAccount()
    }
}

export default Accounts;