import { authHeaders, throwError } from "./utils";

function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const fetchProjects = async (accountSlug, projectName, stageName) => {
    const params = removeEmpty({
        slug: accountSlug,
        name: projectName,
        stage: stageName
    });
    const response = await fetch('/api/projects/?' + new URLSearchParams(params),
        { headers: authHeaders() },
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching projects", response.status)
    }
}

export const fetchProject = async (projectId) => {
    const response = await fetch(`/api/projects/${projectId}/`,
        { headers: authHeaders() },
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching projects", response.status)
    }
}
