import React, { useState } from "react";
import { resetPassword } from "../../../api/register";
import CenterForm from "../../styled/CenterForm";
import Heading from "../../../viewer/components/styled/Heading";
import { Button } from "../../../viewer/components/styled/Button";
import Input from "../../../viewer/components/styled/Input";
import { Success } from "../../styled/Alerts";

const Reset = () => {
    const [email, setEmail] = useState("")
    const [success, setSuccess] = useState(false)

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }

    const reset = async () => {
        await resetPassword(email)
        setSuccess(true)
    }

    const renderMessage = () => {
        if (success) {
            return <Success>Email successfully sent.</Success>
        }
    }

    return (
        <CenterForm>
            <Heading>Send password reset email</Heading>
            {renderMessage()}
            <div className="space-y-4 md:space-y-6">
                <Input label="Email" name="email" value={email} onChange={handleEmailChange} alt="email"></Input>
                <Button name="requestReset" className="w-full" onClick={reset}>Request Reset</Button>
            </div>
        </CenterForm>
    );
}

export default Reset;
