import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { resetPasswordConfirm } from "../../../api/register";
import CenterForm from "../../styled/CenterForm";
import Heading from "../../../viewer/components/styled/Heading";
import { Button } from "../../../viewer/components/styled/Button";
import Input from "../../../viewer/components/styled/Input";
import { Error, Success } from "../../styled/Alerts";

const Confirm = () => {
    const { uid, token } = useParams();
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [error, setError] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const [success, setSuccess] = useState(false)

    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }
    const handlePasswordConfirmChange = (event) => {
        setPasswordConfirm(event.target.value)
    }

    useEffect(() => {
        if (password === passwordConfirm && (password.length > 7 || password.length === 0)) {
            setError(null)
            setDisabled(false)
        } else {
            setError("Password is too short (8 characters) or does not match confirmation")
            setDisabled(true)
        }
    }, [password, passwordConfirm]);

    const reset = async () => {
        try {
            await resetPasswordConfirm(uid, token, password)
            setSuccess(true)
        } catch {
            setError("Error resetting password.")
        }
    }

    const renderMessage = () => {
        if (success) {
            return <Success>Password reset successfully, <Link name="postPasswordResetLogin" className="text-primary" to="/login">Login</Link></Success>
        }
        if (!error) {
            return null
        }
        return (
            <Error>{error}</Error>
        )
    }

    return (
        <CenterForm>
            <Heading>Choose new password</Heading>
            {renderMessage()}
            <div className="space-y-4 md:space-y-6">
                <Input label="Password" name="password" value={password} onChange={handlePasswordChange} type="password" alt="password"></Input>
                <Input label="Confirm Password" name="passwordConfirm" value={passwordConfirm} onChange={handlePasswordConfirmChange} type="password" alt="passwordConfirm"></Input>
                <Button name="resetPasswordConfirm" className="w-full" disabled={disabled} onClick={reset}>Reset Password</Button>
            </div>
        </CenterForm>
    );
}

export default Confirm;
