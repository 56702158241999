import { fetchProfile } from "../api/profile";
import { throwError } from "../api/utils";

export const loadProfile = async (params) => {
    const profile = await fetchProfile()
    if (profile) {
        return profile
    } else {
        throwError(`Profile not found.`, 404);
    }
}