import React, { useEffect, useState } from "react";
import Heading from "../../viewer/components/styled/Heading";
import { useRouteLoaderData } from 'react-router-dom';
import Input from "../../viewer/components/styled/Input"
import LeftForm from "../styled/LeftForm";
import { ButtonOutline } from "../../viewer/components/styled/Button";
import { updateAccount } from "../../api/accounts";
import { Error } from "../styled/Alerts";

const Settings = () => {
  const routeAccount = useRouteLoaderData('account');

  const [account, setAccount] = useState(routeAccount)
  const [name, setName] = useState(account.name)
  const [slug, setSlug] = useState(account.slug)
  const [autoAssociateDomain, setAutoAssociateDomain] = useState(account.auto_associate_domain)
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setDisabled(name === account.name && slug === account.slug && autoAssociateDomain === account.auto_associate_domain)
  }, [account, name, slug, autoAssociateDomain]);

  const handleNameChange = (event) => {
    setName(event.target.value)
    setError(null)
  }

  const handleSlugChange = (event) => {
    setSlug(event.target.value)
    setError(null)
  }

  const handleAutoAssociateDomainChange = (event) => {
    setAutoAssociateDomain(event.target.value)
    setError(null)
  }

  const handleUpdateAccount = async () => {
    try {
      setAccount(await updateAccount(account.id, { name, slug, auto_associate_domain: autoAssociateDomain }))
    } catch (exception) {
      const e = await exception.json()
      if (e.hasOwnProperty("message")) {
        setError(e.message)
      }
    }
  }

  const renderMessage = () => {
    if (!error) {
      return null
    }
    return (
      <Error>{error}</Error>
    )
  }

  return (
    <>
      <Heading>General</Heading>
      <LeftForm>
        {renderMessage()}
        <Input label="Name" name="name" value={name} onChange={handleNameChange} alt="name"></Input>
        <Input label="Slug" name="slug" value={slug} onChange={handleSlugChange} alt="slug"></Input>
        <Input label="Auto Membership from Domain" name="autoAssociateDomain" value={autoAssociateDomain} onChange={handleAutoAssociateDomainChange} alt="autoAssociateDomain"></Input>
        <ButtonOutline disabled={disabled} onClick={() => handleUpdateAccount()}>{'Update'}</ButtonOutline>
      </LeftForm>
    </>
  )
}

export default Settings;
