import React, { useState, useEffect } from "react";
import { updateProfile } from "../../api/profile";
import { fetchTokens, createToken, deleteToken } from "../../api/tokens";
import Loading from "../../viewer/components/Loading";
import Heading from "../../viewer/components/styled/Heading";
import { Button, ButtonOutline } from "../../viewer/components/styled/Button";
import Input from "../../viewer/components/styled/Input";
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { callRefreshAndNavigate } from "../../models/RefreshSession";
import LeftForm from "../styled/LeftForm";
import { Error } from "../styled/Alerts";

const Settings = () => {
    const routeProfile = useRouteLoaderData('profile');
    const [profile, setProfile] = useState(routeProfile)
    const [email, setEmail] = useState(profile.email)
    const [name, setName] = useState(profile.name)
    const [disabled, setDisabled] = useState(true)
    const [error, setError] = useState(null)
    const [tokens, setTokens] = useState(null)
    const [tokenName, setTokenName] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        setDisabled(email === profile.email && name === profile.name)
    }, [profile, email, name]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
        setError(null)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
        setError(null)
    }

    const handleUpdateProfile = async () => {
        try {
            setProfile(await updateProfile({ name, email }))
        } catch (exception) {
            const e = await exception.json()
            if (e.hasOwnProperty("message")) {
                setError(e.message)
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setTokens(await fetchTokens());
            } catch (error) {
                if (error.status === 401) {
                    callRefreshAndNavigate(navigate);
                }
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    const handleTokenNameChange = (event) => {
        setTokenName(event.target.value)
    }
    const createNew = async (event) => {
        const newToken = await createToken(tokenName)
        if (newToken) {
            setTokens([...tokens, newToken])
        }
    }

    const deleteExisting = async (token) => {
        await deleteToken(token.id)
        setTokens(await fetchTokens());
    }

    const renderMessage = () => {
        if (!error) {
            return null
        }
        return (
            <Error>{error}</Error>
        )
    }

    const renderSettings = () => {
        const renderKey = (token) => {
            if (!token.key) {
                return null
            }
            return (
                <div>
                    <div>Save this token now. It will be gone before you know it.</div>
                    <div className="font-bold">{token.key}</div>
                </div>
            )
        }
        return (
            <>
                <Heading>General</Heading>
                <LeftForm>
                    {renderMessage()}
                    <Input label="Name" name="name" value={name} onChange={handleNameChange} alt="name"></Input>
                    <Input label="Email" name="email" value={email} onChange={handleEmailChange} alt="email"></Input>
                    <ButtonOutline disabled={disabled} onClick={() => handleUpdateProfile()}>{'Update'}</ButtonOutline>
                </LeftForm>
                <Heading>
                    <div>Tokens</div>
                    <div className="flex flex-row">
                        <Input label="Token name" alt="tokenName" name="name" value={tokenName} onChange={handleTokenNameChange} />
                        <Button className="ml-2" onClick={createNew}>New</Button>
                    </div>
                </Heading>
                <ol>
                    {tokens.map((token) =>
                        <li key={token.id} className="flex flex-row justify-between items-center hover:bg-gray-100 p-1">
                            <div>{token.name}</div>
                            {renderKey(token)}
                            <Button onClick={() => deleteExisting(token)}>Delete</Button>
                        </li>
                    )}
                </ol>
            </>
        )
    }

    const renderLoading = () => {
        return (
            <Loading />
        )
    }

    if (tokens === null || profile === null) {
        return renderLoading()
    } else {
        return renderSettings()
    }
}

export default Settings;