import { fetchAccounts } from "../api/accounts"
import { throwError } from "../api/utils";

export const loadAccount = async (params) => {
    const accounts = await fetchAccounts(params.params.accountSlug)
    if (accounts && accounts.length === 1) {
        return accounts[0]
    } else {
        throwError(`Account with slug ${params.params.accountSlug} not found.`, 404);
    }
}