import { authHeaders, getErrorMessage, throwError } from "./utils";

export const fetchAccounts = async (accountSlug) => {
    let url = '/api/accounts/'
    if (accountSlug) {
        url += "?" + new URLSearchParams({ slug: accountSlug })
    }
    const response = await fetch(url, { headers: authHeaders() });
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching accounts", response.status)
    }
}

export const updateAccount = async (id, properties) => {
    const response = await fetch(`/api/accounts/${id}/`,
        {
            method: "PUT",
            headers: authHeaders(),
            body: JSON.stringify({ ...properties })
        }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        const message = getErrorMessage(await response.json())
        throwError(message ? message : "Error updating account", response.status)
    }
}