import { getAppBaseUrl, throwError } from './utils';

export const loginUser = async (email, password) => {
    const response = await fetch('/api/register/jwt/create/',
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, password })
        }
    );
    if (response.status === 200 || response.status === 401) {
        return await response.json();
    } else {
        throwError("Error logging in", response.status)
    }
}

export const getGoogleAuthUrl = async () => {
    const url = `/api/register/o/google-oauth2/?redirect_uri=${getAppBaseUrl()}/oauth/callback`;
    const response = await fetch(url,
        {
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error creating authorization url", response.status)
    }
}

export const loginGoogleUser = async (code, state) => {
    const url = `/api/register/o/google-oauth2/?code=${code}&state=${state}`;
    const response = await fetch(url,
        {
            method: "POST",
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 201) {
        return await response.json();
    } else {
        throwError("Error getting token", response.status)
    }
}

export const refreshJwt = async (refresh) => {
    const url = `/api/register/jwt/refresh/`;
    const response = await fetch(url,
        {
            method: "POST",
            body: JSON.stringify({ refresh }),
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error refreshing token", response.status)
    }
}

export const activateEmail = async (uid, token) => {
    const url = `/api/register/users/activation/`;
    const response = await fetch(url,
        {
            method: "POST",
            body: JSON.stringify({ uid, token }),
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 204) {
        return;
    } else {
        throwError("Error activating email", response.status)
    }
}

export const resetPassword = async (email) => {
    const url = `/api/register/users/reset_password/`;
    const response = await fetch(url,
        {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 204) {
        return;
    } else {
        throwError("Error resetting password", response.status)
    }
}

export const resetPasswordConfirm = async (uid, token, password) => {
    const url = `/api/register/users/reset_password_confirm/`;
    const response = await fetch(url,
        {
            method: "POST",
            body: JSON.stringify({ uid, token, new_password: password }),
            headers: { "Content-Type": "application/json" },
        }
    );
    if (response.status === 204) {
        return;
    } else {
        throwError("Error resetting password", response.status)
    }
}