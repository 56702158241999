
import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { useParams, useRouteLoaderData, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import QueryContext from '../viewer/contexts/QueryContext'
import Select from 'react-select'
import { Tooltip } from "@mui/material";

const ProjectHistory = () => {
    let project = useRouteLoaderData('project')

    const { fetchProjectHistoryQuery } = useContext(QueryContext);
    const { accountSlug, stageName, dashboardName } = useParams()

    const navigate = useNavigate()

    const { data, isLoading, isError } = useQuery(fetchProjectHistoryQuery(project?.id))

    if (!project || !project.id || isLoading || isError) {
        return null;
    }

    const onChange = (option) => {
        const isLatest = data.findIndex(history_project => option.value === history_project.id) === 0;

        let projectIdUrl;
        if (isLatest) {
            projectIdUrl = project.name
        } else {
            projectIdUrl = `${option.value}`
        }
        const dashboardNameUrl = dashboardName ? `/${dashboardName}` : ""

        const url = `/${accountSlug}/${stageName}/${projectIdUrl}${dashboardNameUrl}`
        navigate(url)
    }
    const renderSelect = (history) => {
        if (!history || history.length === 1) {
            return (
                <span data-testid="project-history">
                    {new Date(Date.parse(project.created_at)).toLocaleString()}
                </span>
            )
        } else {
            const options = history.map(history_project => {
                return { value: history_project.id, label: new Date(Date.parse(history_project.created_at)).toLocaleString() }
            })
            const customStyles = {
                container: provided => ({
                    ...provided,
                    minWidth: "250px"
                })
            };
            return (
                <div className="ml-2" data-testid="project-history-select" >
                    <Select
                        styles={customStyles}
                        value={options.find(option => option.value === project.id)}
                        onChange={onChange}
                        options={options} />
                </div>
            )
        }
    }

    return (
        <span className="mr-2 flex flex-row items-center">
            <Tooltip title="Select different versions of this project and look back in time.">
                <FontAwesomeIcon icon={faClock} />
            </Tooltip>
            {renderSelect(data)}
        </span>
    );
}

export default ProjectHistory;

