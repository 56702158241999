import { json } from "react-router-dom";
import { getAccessToken } from '../models/Session';

export const authHeaders = () => {
    return { "Content-Type": "application/json", 'Authorization': `JWT ${getAccessToken()}` }
}

export const throwError = (message, status) => {
    throw json(
        { message },
        { status }
    );
}

export const getErrorMessage = (error) => {
    return Object.keys(error).map((key) => {
        return `${key}: ${error[key][0]}`
    }).join("\n")
}

export const getAppBaseUrl = () => {
    return process.env.REACT_APP_BASE_APP_URL
}