import { authHeaders, throwError } from './utils';

export const fetchMemberships = async (accountId) => {
    const response = await fetch('/api/memberships/?' + new URLSearchParams({
        account_id: accountId
    }), { headers: authHeaders() });
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching memberships", response.status)
    }
}


export const deleteMembership = async (id) => {
    const response = await fetch(`/api/memberships/${encodeURIComponent(id)}/`,
        {
            method: 'DELETE',
            headers: authHeaders(),
        });
    if (response.status === 204) {
        return true;
    } else {
        return false;
    }
}
