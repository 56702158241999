import React, { useState, useEffect } from "react";
import { fetchMemberships, deleteMembership } from "../../api/memberships";
import { fetchInvites, createInvite, deleteInvite } from "../../api/invites";
import { fetchSubscriptions } from "../../api/subscriptions";
import Heading from "../../viewer/components/styled/Heading";
import Input from "../../viewer/components/styled/Input";
import { Button, ButtonHighlight } from "../../viewer/components/styled/Button";
import { useRouteLoaderData } from 'react-router-dom';
import Spinner from "../../viewer/components/styled/Spinner";
import { getSessionUser } from "../../models/Session";

const Memberships = () => {
    const [memberships, setMemberships] = useState(null)
    const [invites, setInvites] = useState(null)
    const [inviteEmail, setInviteEmail] = useState("")
    const [subscriptions, setSubscriptions] = useState(null)
    const account = useRouteLoaderData('account');

    useEffect(() => {
        const fetchData = async () => {
            setMemberships(await fetchMemberships(account.id));
        }
        if (account) {
            fetchData();
        }
    }, [account]);

    useEffect(() => {
        const fetchData = async () => {
            setInvites(await fetchInvites(account.id));
        }
        if (account) {
            fetchData();
        }
    }, [account]);

    useEffect(() => {
        const fetchData = async () => {
            setSubscriptions(await fetchSubscriptions(account.id));
        }
        if (account) {
            fetchData();
        }
    }, [account]);

    const deleteExistingMembership = async (membership) => {
        await deleteMembership(membership.id)
        setMemberships(await fetchMemberships(account.id));
    }

    const deleteExistingInvite = async (invite) => {
        await deleteInvite(invite.id)
        setInvites(await fetchInvites(account.id));
    }

    const createNewInvite = async (event) => {
        if (!addingUserEnabled()) {
            return;
        }
        const newInvite = await createInvite(inviteEmail, account)
        if (newInvite) {
            setInvites([...invites, newInvite])
        }
    }
    const handleEmailChange = (event) => {
        setInviteEmail(event.target.value)
    }

    const addingUserEnabled = () => {
        if (!subscriptions || !invites || !memberships || !subscriptions[0]) {
            return false;
        }
        const subscription = subscriptions[0];
        if (memberships.length + invites.length >= subscription.seats) {
            return false;
        }

        return true;
    }

    const renderDeleteMembership = (membership) => {
        if (getSessionUser()["user_id"] !== membership.user.id) {
            return (
                <ButtonHighlight onClick={() => deleteExistingMembership(membership)}>Delete</ButtonHighlight>
            )
        }
    }

    const renderMemberships = () => {
        const heading = "Memberships"
        if (!memberships) {
            return (
                <>
                    <Heading>{heading}</Heading>
                    <Spinner />
                </>
            )
        }

        return (
            <>
                <Heading>{heading}</Heading>
                <ol>
                    {memberships.map((membership) =>
                        <li key={membership.id} className="flex flex-row justify-between items-center hover:bg-gray-100 p-1">
                            <div className="flex flex-row">
                                <div className="mr-4"><span className="font-light mr-2">Display Name:</span>{membership.user.name ? membership.user.name : "(Not Set)"}</div>
                                <div><span className="font-light mr-2">Email:</span>{membership.user.email}</div>
                            </div>
                            {renderDeleteMembership(membership)}
                        </li>
                    )}
                </ol>
            </>
        )
    }

    const renderInviteButton = () => {
        if (addingUserEnabled()) {
            return (
                <div className="flex flex-row">
                    <Input label="Email to invite" name="email" alt="inviteEmail" value={inviteEmail} onChange={handleEmailChange} />
                    <Button className="ml-2" onClick={createNewInvite}>Invite</Button>
                </div>
            )
        }
    }

    const renderInvites = () => {
        const heading = "Invites"
        if (!invites) {
            return (
                <>
                    <Heading>{heading}</Heading>
                    <Spinner />
                </>
            )
        }

        return (
            <>
                <Heading>
                    <div>{heading}</div>
                    {renderInviteButton()}
                </Heading>
                <ol>
                    {invites.map((invite) =>
                        <li key={invite.id} className="flex flex-row justify-between hover:bg-gray-100 items-center p-1">
                            <div>{invite.email}</div>
                            <ButtonHighlight onClick={() => deleteExistingInvite(invite)}>Delete</ButtonHighlight>
                        </li>
                    )}
                </ol>
            </>
        )
    }

    return (
        <>
            {renderMemberships()}
            {renderInvites()}
        </>
    );
}

export default Memberships;
