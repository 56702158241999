import { authHeaders, throwError } from "./utils";

export const fetchStages = async (accountId, params) => {
    const response = await fetch('/api/stages/?' + new URLSearchParams({
        account_id: accountId, ...params
    }),
        { headers: authHeaders() },
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching stages", response.status)
    }
}

export const updateStage = async (id, properties) => {
    const response = await fetch(`/api/stages/${id}/`,
        {
            method: "PUT",
            headers: authHeaders(),
            body: JSON.stringify({ ...properties })
        }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error updating stage", response.status)
    }
}