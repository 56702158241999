import tw from "tailwind-styled-components"

const Card = tw.div`
  m-1
  flex-grow
  p-6
  bg-white
  border
  border-gray-200
  rounded-lg
  shadow
`;

export default Card;