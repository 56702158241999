import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode";

export const getSession = () => {
    const jwtString = Cookies.get('__visivo_session')
    let session
    if (jwtString) {
        const jwt = JSON.parse(jwtString)
        session = { ...jwt }
    }
    return session
}

export const hasNoSession = () => {
    const session = getSession()
    if (!session) {
        return true
    }
    return false
}

export const getSessionUser = () => {
    const session = getSession()
    return jwtDecode(session["access"])
}

export const getAccessToken = () => {
    const session = getSession()
    if (!session) {
        return null
    }
    return session["access"]
}

export const getRefreshToken = () => {
    const session = getSession()
    return session["refresh"]
}

export const storeSession = (session) => {
    Cookies.set('__visivo_session', JSON.stringify(session))
}

export const clearSession = () => {
    Cookies.remove('__visivo_session')
}