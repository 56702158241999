import { fetchProjects, fetchProject } from "../api/projects"
import { throwError } from "../api/utils";
import { validate as uuidValidate } from 'uuid';

export const loadProject = async (params) => {
    const { accountSlug, projectName, stageName } = params.params
    if (projectName && uuidValidate(projectName)) {
        return await fetchProject(projectName)
    } else {
        const projects = await fetchProjects(accountSlug, projectName, stageName)
        if (projects.length !== 1) {
            throwError(`Project with name ${projectName} not found.`, 404);
        }
        return projects[0];
    }
}