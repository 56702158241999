import { authHeaders, throwError } from './utils';

export const fetchTokens = async () => {
    const response = await fetch('/api/me/api_keys/',
        { headers: authHeaders() }
    );
    if (response.status === 200) {
        return await response.json();
    } else {
        throwError("Error fetching token", response)
    }
}

export const createToken = async (name) => {
    const response = await fetch('/api/me/api_keys/',
        {
            method: "POST",
            headers: authHeaders(),
            body: JSON.stringify({ name })
        }
    );
    if (response.status === 201) {
        return await response.json();
    } else {
        throwError("Error creating token", response.status)
    }
}

export const deleteToken = async (id) => {
    const response = await fetch(`/api/me/api_keys/${encodeURIComponent(id)}/`,
        {
            method: 'DELETE',
            headers: authHeaders(),
        });
    if (response.status === 204) {
        return true;
    } else {
        throwError("Error deleting token", response.status)
    }
}
